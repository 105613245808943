<template>
  <div class="container-fluid">
    <PageTitle noAdd />

    <b-overlay :show="loadingOverlay||loadingOverlayTb">
      <b-card v-if="!!user.id_ghost">
        <!-- <b-row>
          <b-col md="10">
            <b-form-group label="Level:" label-cols="3">
              <VSelect
                :value="(tUser.user_ghost||{}).officeLevel"
                placeholder="-- Pilih Level --"
                :options="mrTipe"
                :reduce="(v) => String(v.value)"
              />
            </b-form-group>

            <b-form-group label="Kantor:" label-cols="3">
              <VSelect
                :value="(tUser.user_ghost||{}).officeId"
                placeholder="-- Pilih Kantor --"
                :options="mrOffice"
                :reduce="(v) => String(v.value)"
              />
            </b-form-group>

            <b-form-group label="Role" label-cols="3">
              <VSelect
                :value="(tUser.user_ghost||{}).roleSelected"
                :placeholder="'-- Pilih Role --'"
                :options="mrRole"
                :reduce="(v) => String(v.value)"
              />
            </b-form-group>

            <b-form-group v-if="(tUser.user_ghost||{}).unitName" :label="(isPusat?'Divisi:':'Unit Kerja:')" label-cols="3">
              <VSelect
                :value="(tUser.user_ghost||{}).unitName"
                :placeholder="'-- Pilih '+(isPusat?'Divisi':'Unit Kerja')+' --'"
                :options="mrUnitKerja"
                :reduce="(v) => String(v.value)"
                @input="doGetUser"
              />
            </b-form-group>

            <b-form-group label="User" label-cols="3">
              <VSelect
                v-model="filter_user"
                :placeholder="'-- Pilih User --'"
                :options="mrUser"
                :reduce="(v) => String(v.bu_id)"
                label="bu_full_name"
              />
              <VValidate
                name="User"
                v-model="filter_user"
                rules="required"
              />
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col md=8>
            <b-form-group label="Anda Login Sebagai:" label-cols="3">
              <VSelect
                :value="(tUser.user_ghost||{}).id"
                :placeholder="'-- Pilih User --'"
                :options="mrUser"
                :reduce="(v) => String(v.bu_id)"
                label="bu_full_name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md=1></b-col>
          <b-col md=3>
            <b-button variant="info" block @click="doCancelGantiKantor">
              Kembali Ke Root
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-else>
        <b-card-title>
          <b-row>
            <b-col>
              Ganti Kantor
            </b-col>
          </b-row>
        </b-card-title>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitForm)">
            <b-row>
              <b-col md="10">
                <b-form-group label="Level:" label-cols="3">
                  <VSelect
                    v-model="filter_tipe"
                    placeholder="-- Pilih Level --"
                    :options="mrTipe"
                    :reduce="(v) => String(v.value)"
                    @input="doChangeTipe"
                  />
                  <VValidate
                    name="Level"
                    v-model="filter_tipe"
                    rules="required"
                  />
                </b-form-group>

                <b-form-group label="Kantor:" label-cols="3">
                  <VSelect
                    v-model="filter_kantor"
                    placeholder="-- Pilih Kantor --"
                    :options="mrOffice"
                    :reduce="(v) => String(v.value)"
                    @input="doChangeKantor"
                  />
                  <VValidate
                    name="Kantor"
                    v-model="filter_kantor"
                    rules="required"
                  />
                </b-form-group>

                <b-form-group label="Role" label-cols="3">
                  <VSelect
                    v-model="filter_role"
                    :placeholder="'-- Pilih Role --'"
                    :options="mrRole"
                    :reduce="(v) => String(v.value)"
                    @input="doGetUser"
                  />
                  <VValidate
                    name="Role"
                    v-model="filter_role"
                    rules="required"
                  />
                </b-form-group>

                <b-form-group v-if="showFilterUnitKerja" :label="(isPusat?'Divisi:':'Unit Kerja:')" label-cols="3">
                  <VSelect
                    v-model="filter_unit_kerja"
                    :placeholder="'-- Pilih '+(isPusat?'Divisi':'Unit Kerja')+' --'"
                    :options="mrUnitKerja"
                    :reduce="(v) => String(v.value)"
                    @input="doGetUser"
                  />
                  <VValidate
                    :name="(isPusat?'Divisi':'Unit Kerja')"
                    v-model="filter_unit_kerja"
                    rules="required"
                  />
                </b-form-group>

                <b-form-group label="User" label-cols="3">
                  <VSelect
                    v-model="filter_user"
                    :placeholder="'-- Pilih User --'"
                    :options="mrUser"
                    :reduce="(v) => String(v.bu_id)"
                    label="bu_full_name"
                  />
                  <VValidate
                    name="User"
                    v-model="filter_user"
                    rules="required"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div v-if="!moduleRole('ViewOnly')" align="right">
              <b-button type="submit" variant="info" size="lg" :disabled="!mrUser.length">
                Mulai Ganti
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
      
  </div>
</template>

<script>
import Gen from '@libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'

let _ = global._
let $ = global.jQuery
export default {
  extends:GlobalVue,
  components: { 
    PageTitle 
  },
  data(){
    return {
      filter_tipe:"",
      filter_kantor:"",
      filter_unit_kerja:"",
      filter_role:"",
      filter_user:"",
      mrTipe:[
        {value: 'pusat', label:'Pusat'},
        {value: 'cabang', label:'Branch Office'},
        {value: 'perwakilan', label:'Marketing Office'},
      ],
      mrRole:[
        {value:'isRiskOfficer', label:'Risk Officer'},
        {value:'isPenaksir', label:'Penaksir'},
        {value:'isPJRTU', label:'PJRTU'},
      ],
      mrOfficeTemp:[],
      mrUnitKerjaTemp: [],
      mrUser:[],
      tUser:{}
    }
  },
  computed:{
    isPusat() {
      return _.filter(this.mrOffice, (v) => {
        return v.value == this.filter_kantor && v.level=='pusat';
      }).length;
    },
    showFilterUnitKerja(){
      return !(
        this.filter_tipe!='pusat' && !!this.filter_tipe &&
        ['isRiskOfficer','isPJRTU'].indexOf(this.filter_role)>-1
      )
    },
    mrOffice(){
      const FilterOffice = _.filter(_.clone(this.mrOfficeTemp), {level: this.filter_tipe})||[]

      return FilterOffice
    },
    mrUnitKerja(){
      let LevelKantor = _.map(_.filter(_.clone(this.mrOfficeTemp), (v) => {
        return v.value == this.filter_kantor
      }), (v)=>{
        return v.level
      })
      LevelKantor = LevelKantor=='pusat'?'divisi':LevelKantor

      let FilterUnit = _.filter(JSON.parse(JSON.stringify(this.mrUnitKerjaTemp)), (v)=>{
        if(LevelKantor==='divisi'){
          return v.level==LevelKantor 
        } else {
          return v.level==LevelKantor
        }
      })

      return FilterUnit
    }
  },
  methods:{
    doChangeTipe(){
      this.filter_kantor = ""
      this.filter_unit_kerja = ""
      this.doGetUser()
    },
    doChangeKantor() {
      this.filter_unit_kerja = ""
      this.doGetUser()
    },
    doGetUser(){
      if((!this.filter_unit_kerja && this.showFilterUnitKerja) || !this.filter_kantor || !this.filter_role) return false
      
      this.loadingOverlayTb = true
      this.filter_user = ""
      this.apiGet({
        query: {
          type:'getUser',
          filter_tipe: this.filter_tipe,
          filter_role: this.filter_role,
          filter_unit_kerja: this.filter_unit_kerja,
          filter_kantor: this.filter_kantor,
        }
      })
    },
    doCancelGantiKantor(){
			this.loadingOverlay=true
			Gen.apiRest(
				"/do/GantiKantor",
				{
					data:{
						type: 'cancel-ganti'
					}
				},
				'POST'
			).then(()=>{
				window.location = this.$router.resolve({name:'Dashboard'}).href
			});
		},
    doSubmitForm(){
      this.doSubmit(
        "/do/"+this.modulePage, 
        {
          type:'ganti',
          filter_user: this.filter_user
        }, 
        (type, resp)=>{
          if(type=='success'){
            window.location = this.$router.resolve({name:'Dashboard'}).href
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data),(i,v)=>{
                  msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              }else{
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            }else if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        }
      )
    },
  },
  mounted() {
    this.apiGet();
  },
  watch:{
    $route(){
      this.apiGet()
    }
  },
}
</script>